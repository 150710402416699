import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiEndPoints = API_ENDPOINTS;

  constructor(private http: HttpClient) { }

  setCsrf(): Observable<any> {
    return this.http.get<any>(this.apiEndPoints.auth.csrf_cookie);
  }

  isLoggedIn(): Observable<any> {
    return this.http.get<any>(this.apiEndPoints.auth.is_loggedin, {
      params :{ role: 'admin' }
    }); 
  }

  login(data): Observable<any> {
    return this.http.post<any>(this.apiEndPoints.auth.login, data);
  }

  logout(): Observable<any> {
    return this.http.post(this.apiEndPoints.auth.logout, []);
  }

  forgotPassword(email): Observable<any> {
    return this.http.post(this.apiEndPoints.auth.forgot_password, { email });
  }

  resetPassword(data): Observable<any> {
    return this.http.post(this.apiEndPoints.auth.reset_password, data);
  }

  setPassword(data): Observable<any> {
    return this.http.post(this.apiEndPoints.auth.set_password, data);
  }

}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './shared/services/auth.service';
import { LocalstorageService } from './shared/services/localstorage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    isLoading = true;

    guestRoutes = [
        '',
        'authentication/login',
        'authentication/forgot-password',
        'authentication/reset-password',
        'authentication/set-password',
        'authentication/file-download/:hash'
    ];

    constructor(
        private loation: Location,
        private router: Router,
        private authService: AuthService,
        private localstorageService: LocalstorageService,
        // private notification: NzNotificationService,
    ) {}

    ngOnInit(): void {
        this.validateAuthentication();
    }

    validateAuthentication() {
        const url = this.loation.path();
        const parsedUrl = this.router.parseUrl(url);
        let baseUrl = '';

        if (url !== '') {
            baseUrl = parsedUrl.root.children.primary.segments.map(it => it.path).join('/');
        }

        this.authService.isLoggedIn().subscribe(
            (data) => {
                this.localstorageService.setUserProfile(data.profile);
                this.localstorageService.setRoles(data.roles);
                if(this.checkUserRole()) {
                    this.localstorageService.setGlobalUserProfile();
                    localStorage.setItem('isLoggedIn', 'true');
                    if(baseUrl.includes('file-download'))
                    {
                        this.router.navigate([baseUrl]);
                    }
                    else
                    {
                        if (this.guestRoutes.includes(baseUrl)) {
                            this.router.navigate(['/company']);
                        }
                    }
                    this.isLoading = false;
                } else {
                    this.router.navigate(['/authentication/login']);
                }
            },
            (error) => {
                this.isLoading = false;
                if (!this.guestRoutes.includes(baseUrl)) {
                    this.router.navigate(['/authentication/login']);
                }
            }
        );
    }

    // Check user role and redirect to apporpriate application endpoint.
    checkUserRole(): any {
        if(this.localstorageService.hasRole('admin')) {
            return true;
        }

        return false;
    }
}

import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    // {
    //     path: 'home',
    //     title: 'Dashboard',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'dashboard',
    //     submenu: []
    // },
    {
        path: 'company',
        title: 'Organizational Units',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'cluster',
        submenu: []
    },
    {
        path: 'home-health',
        title: 'Home Health Care',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'shop',
        submenu: []
    },
    {
        path: 'user-access-roles',
        title: 'User Access Controls',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'control',
        submenu: []
    },
    /* {
        path: 'providers',
        title: 'Provider Manager',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user',
        submenu: []
    }, */
    /* {
        path: 'patients',
        title: 'Patient Manager',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user',
        submenu: []
    },
    {
        path: 'archive-patients',
        title: 'Archive Patients',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-delete',
        submenu: []
    }, */
    {
        path: 'inventory-management',
        title: 'Inventory Management',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'mobile',
        submenu: []
    },
];

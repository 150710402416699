import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { USER_PROFILE } from '../../services/user-profile';
import { PermissionService } from '../../services/permission.service';
import { Globals } from 'src/app/globals';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent{

    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;
    isExpand : boolean;
    userProfile = USER_PROFILE;

    constructor( 
        private themeService: ThemeConstantService,
        private permission: PermissionService,
        public globals: Globals) {}

    ngOnInit(): void {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.getPermissions();
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }

    getPermissions(): void {
        this.permission.getLoggedInUserPermissions({}).subscribe({
          next: (data) => {
            if (data.data?.includes('referral_manager')) {
                let extraMenu = {
                    path: 'referrals',
                    title: 'Referrals',
                    iconType: 'nzIcon',
                    iconTheme: 'outline',
                    icon: 'usergroup-add',
                    submenu: []
                };      
                this.menuItems.push(extraMenu);
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
    }
}
